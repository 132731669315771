export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'home',
  },
  {
    title: 'Database Advokat',
    route: 'biodata-list',
    icon: 'DatabaseIcon',
    action: 'read',
    resource: 'list-biodata',
  },
  {
    title: 'Keanggotaan',
    icon: 'UsersIcon',
    children: [
      // This is array of menu items or menu groups
      // NOTE: You can't use menu header as children
      {
        title: 'Permohonan',
        route: 'permohonan-list',
        action: 'read',
        resource: 'internal',
      },
      // {
      //   title: 'Daftar Probono',
      //   route: 'probono-laporan-keanggotaan',
      //   action: 'read',
      //   resource: 'internal',
      // },
      // {
      //   title: 'Daftar CLE',
      //   route: 'cle-keanggotaan',
      //   action: 'read',
      //   resource: 'internal',
      // },
    ],
  },
  {
    title: 'Layanan Mandiri',
    icon: 'UserCheckIcon',
    children: [
      // This is array of menu items or menu groups
      // NOTE: You can't use menu header as children
      {
        title: 'Biodata',
        route: 'mandiri-biodata',
        action: 'read',
        resource: 'layanan-mandiri',
      },
      {
        title: 'Permohonan',
        route: 'mandiri-list-permohonan',
        action: 'read',
        resource: 'layanan-mandiri',
      },
      // {
      //   title: 'Probono',
      //   route: 'probono-laporan',
      //   action: 'read',
      //   resource: 'probono-laporan',
      // },
      // {
      //   title: 'CLE',
      //   route: 'cle',
      //   action: 'read',
      //   resource: 'cle',
      // },
    ],
  },
  {
    title: 'Faq',
    route: 'faq',
    icon: 'HelpCircleIcon',
    action: 'read',
    resource: 'home',
  },
  {
    title: 'Bantuan',
    route: 'support',
    icon: 'MessageCircleIcon',
    action: 'read',
    resource: 'home',
  },
  // {
  //   title: 'Notifikasi',
  //   route: 'notifikasi',
  //   icon: 'MessageSquareIcon',
  //   action: 'read',
  //   resource: 'home',
  // },
  {
    title: 'Pelaporan',
    route: 'pelaporan',
    icon: 'FileIcon',
  },
  {
    title: 'Pencetakan',
    icon: 'UserCheckIcon',
    children: [
      // This is array of menu items or menu groups
      // NOTE: You can't use menu header as children
      {
        title: 'Status Cetak',
        route: 'pencetakan-status',
        icon: 'DatabaseIcon',
        action: 'write',
        resource: 'pencetakan',
      },
      {
        title: 'Ready Cetak',
        route: 'pencetakan-ready-cetak',
        icon: 'DatabaseIcon',
        action: 'read',
        resource: 'pencetakan',
      },
      {
        title: 'Selesai Cetak',
        route: 'pencetakan-selesai-cetak',
        icon: 'DatabaseIcon',
        action: 'read',
        resource: 'pencetakan',
      },
      {
        title: 'Batal Cetak',
        route: 'pencetakan-batal-cetak',
        icon: 'DatabaseIcon',
        action: 'read',
        resource: 'pencetakan',
      },
      {
        title: 'Statistik',
        route: 'stats',
        icon: 'DatabaseIcon',
        action: 'read',
        resource: 'pencetakan',
      },
      // {
      //   title: 'Ready Cetak OLD',
      //   route: 'pencetakan-list',
      //   icon: 'DatabaseIcon',
      //   action: 'read',
      //   resource: 'pencetakan',
      // },
    ],
  },
  {
    title: 'Users',
    route: 'userlists',
    icon: 'UsersIcon',
  },
]
